import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import { Layout, PostCard, Pagination, Authors, FullHeader } from '../components/common'
import { MetaData } from '../components/common/meta'
import config from '../utils/siteConfig'
import { device } from "../utils/device.js"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import {HeaderXXL, BodyText} from "../utils/typography"

/**
* Main index page (home page)
*
* Loads all posts from Ghost and uses pagination to navigate through them.
* The number of posts that should appear per page can be setup
* in /utils/siteConfig.js under `postsPerPage`.
*
*/
const Index = ({ data, location, pageContext }) => {

    const blog = data.blog.edges
    const work = data.work.edges
    const page = data.page
    const workTag = data.tagWork
    const blogTag = data.tagBlog
    const authors = data.authors.edges

    return (
        <>
            <MetaData location={location} />
            <Layout isHome={true}>
                    <FullHeader/>

                    <CenteredContent>
                      <IndexContainer>
                          <IndexHeader>
                            <h2>{workTag.name}</h2>
                          </IndexHeader>
                          <Container >
                            {work.map(({ node }) => (
                                // The tag below includes the markup for each post - components/common/PostCard.js
                                <PostCard key={node.id} url={node.url} text={node.excerpt} title={node.title}  img={node.localFeatureImage}/>
                            ))}
                            <PostCard key={workTag.id} url={workTag.slug} text={workTag.description} title={workTag.name}  img={workTag.localFeatureImage}/>
                          </Container>
                        </IndexContainer>

                      <IndexContainer>
                          <IndexHeader>
                            <h2>{blogTag.name}</h2>
                          </IndexHeader>
                          <Container >
                              {blog.map(({ node }) => (
                                  // The tag below includes the markup for each post - components/common/PostCard.js
                                  <PostCard key={node.id} url={node.url} text={node.excerpt} title={node.title} img={node.localFeatureImage}/>
                              ))}
                              <PostCard key={blogTag.id} url={blogTag.slug} text={blogTag.description} title={blogTag.name}  img={blogTag.localFeatureImage}/>
                          </Container>
                      </IndexContainer>

                      <IndexContainer>
                        <IndexHeader>
                          <h2>Contact.</h2>
                        </IndexHeader>
                        <Authors authors={authors}/>
                      </IndexContainer>
                    </CenteredContent>
            </Layout>
        </>
    )
}

const IndexContainer = styled("div")`
  margin: 10rem 0;
`

const IndexHeader = styled("div")`
  margin: -0.225em 0.2em;
  padding: 0;
  z-index: 10;
  position: relative;
  ${HeaderXXL}

  >h2{
    margin: 0;
    padding: 0;
    display: block;
    color: ${config.textColorDark};
    }
`

const CenteredContent = styled("div")`
  margin-left: auto;
  margin-right: auto;
  max-width: ${config.contentMaxWidth};
  width: 95%;

  @media ${device.desktop} {
    max-width: calc(${config.contentMaxWidth} * 1.2);
  }
`

const Container = styled("div")`
  z-index: 0;
  width: 100%;
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
`



export default Index

// This page query loads all posts sorted descending by published date
// The `limit` and `skip` values are used for pagination
export const pageQuery = graphql`
query {
    page: ghostPage(slug: { eq: "home" }) {
        ...GhostPageFields
    }

    blog: allGhostPost(
        filter: {tags: {elemMatch: {slug: {eq: "blog"}}}},
        limit: 3,
        sort: { order: DESC, fields: [published_at] }) {
      edges {
        node {
          ...GhostPostFields
          localFeatureImage{
            childImageSharp {
              fluid(maxWidth: 1440, maxHeight: 800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }

    work: allGhostPost(
        filter: {tags: {elemMatch: {slug: {eq: "work"}}}},
        limit: 3,
        sort: { order: DESC, fields: [published_at] }) {
      edges {
        node {
          ...GhostPostFields
          localFeatureImage{
            childImageSharp {
              fluid(maxWidth: 1440, maxHeight: 800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }

    tagWork: ghostTag(slug: { eq: "work" }) {
          ...GhostTagFields
          localFeatureImage{
            childImageSharp {
              fluid(maxWidth: 1440, maxHeight: 800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
      }

    tagBlog: ghostTag(slug: { eq: "blog"}) {
          ...GhostTagFields
          localFeatureImage{
            childImageSharp {
              fluid(maxWidth: 1440, maxHeight: 800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
      }

    authors: allGhostAuthor(sort: { order: DESC, fields: [name] }){
      edges {
        node {
          ...GhostAuthorFields
          localProfileImage {
            childImageSharp {
              fluid(maxWidth: 400, maxHeight: 250) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
}
`
